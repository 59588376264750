import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ShopOrdersStore from '../../stores/ShopOrdersStore';
import JobStore from '../../stores/JobStore';
import OrderForm from './OrderForm';
import {
  Box, Typography, CircularProgress, Snackbar, Alert, Modal, Paper, IconButton, Button, useTheme,
  Badge
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PDFViewer } from '@react-pdf/renderer';
import JobSheetsPDF from '../../pdf/JobSheetsPDF';
import DailyMachineSheetPDF from '../../pdf/DailyMachineSheetPDF';

const EditOrder = () => {
  const [order, setOrder] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'error' });
  const [viewJobSheet, setViewJobSheet] = useState(false);
  const [viewMachineSheet, setViewMachineSheet] = useState(false);
  const [productionsEnabled, setProductionEnabled] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    ShopOrdersStore.fetchShopOrderById(orderId)
      .then((shopOrder) => {
        setOrder(shopOrder);
      })
      .catch(() => {
        setSnackbar({ open: true, message: 'Failed to fetch order', severity: 'error' });
      });
  }, [orderId]);

  useEffect(() => {
    if (order) {
      async function fetchProductions() {
        const jobFilter = { orderId: orderId };
        const jobs = await JobStore.fetchPaginatedJobs(1, 10, '-dueDate', jobFilter);

        if (jobs && jobs.results.length > 0) {
          setProductionEnabled(true);
        } else {
          setProductionEnabled(false);
        }
      }

      fetchProductions();
    }
  }, [order]);

  const handleSubmit = async () => {
    try {
      await ShopOrdersStore.updateShopOrder(orderId, order);
      setSnackbar({ open: true, message: 'Order updated successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to update order', severity: 'error' });
    }
  };

  const handleChange = (updatedOrder) => {
    setOrder(updatedOrder);
  };

  const handleView = () => {
    navigate(`/orders/${orderId}`);
  };

  const handleBack = () => {
    navigate('/orders');
  };

  const handleProductionsClick = () => {
    navigate(`/productions`, { state: { orderId: orderId } });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  if (!order) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h4" sx={{ ml: 2 }}>
            Edit Order - {order.salesOrderNumber}
          </Typography>
        </Box>

        <IconButton onClick={handleView}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box display="flex" sx={{ gap: 2, alignItems: 'center', mt: 2 }}>
        <Badge
          badgeContent={JobStore.paginatedJobs?.totalCount || 0}
          color={JobStore.paginatedJobs?.totalCount > 0 ? 'success' : 'secondary'}
          invisible={!productionsEnabled || JobStore.paginatedJobs?.totalCount === 0}
        >
          <Button
            variant="text"
            onClick={handleProductionsClick}
            disabled={!productionsEnabled}
          >
            Productions
          </Button>
        </Badge>

        <Button
          variant="text"
          onClick={() => setViewJobSheet(true)}
        >
          Job Sheet
        </Button>

        <Button
          variant="text"
          onClick={() => setViewMachineSheet(true)}
        >
          Machine Production Sheet
        </Button>
      </Box>

      <OrderForm order={order} onChange={handleChange} onSubmit={handleSubmit} />

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {order && (
        <>
          {viewJobSheet && (
            <Modal
              open={viewJobSheet}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={() => setViewJobSheet(false)}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width="100%" height="100%">
                  <JobSheetsPDF orderInfo={order} />
                </PDFViewer>
              </Paper>
            </Modal>
          )}
          {viewMachineSheet && (
            <Modal
              open={viewMachineSheet}
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <Paper sx={{ width: '90%', height: '90%', padding: 1 }}>
                <IconButton
                  onClick={() => setViewMachineSheet(false)}
                  sx={{ position: 'absolute', top: 0, right: 0, padding: 1, margin: 1, color: theme.palette.background.default }}
                >
                  <CloseIcon />
                </IconButton>
                <PDFViewer width="100%" height="100%">
                  <DailyMachineSheetPDF orderInfo={order} />
                </PDFViewer>
              </Paper>
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};

export default EditOrder;
