import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  InputAdornment,
  MenuItem,
  CircularProgress,
  Autocomplete,
  Typography
} from "@mui/material";
import PayablesStore from '../../stores/PayablesStore';

const PayableForm = ({ initialPayableData, onSubmit }) => {
  const [payable, setPayable] = useState({
    invoiceNo: "",
    checkNo: 0,
    invoiceAmount: 0,
    paidAmount: 0,
    commentsExternal: "",
    commentsInternal: "",
    dueDate: "",
    expenseCode: {
      _id: '',
      acctCodeId: '',
      accountCode: ''
    },
    vendor: {
      _id: '',
      vendorId: '',
      vendorName: ''
    }
    // Add other fields as needed
  });
  const [accountCodes, setAccountCodes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (initialPayableData) {
      setPayable({
        ...payable,
        ...initialPayableData,
        expenseCode: initialPayableData.expenseCode || { _id: '', acctCodeId: '', accountCode: '' },
        vendor: initialPayableData.vendor || { _id: '', vendorId: '', vendorName: '' },
      });
    }

    const fetchAccountCodes = async () => {
      setLoading(true);
      try {
        const codes = await PayablesStore.fetchAccountCodes();
        // Sort account codes by code number for easier browsing
        const sortedCodes = codes.sort((a, b) => 
          a.accountCode.localeCompare(b.accountCode, undefined, { numeric: true })
        );
        console.log(`Fetched ${sortedCodes.length} account codes`);
        setAccountCodes(sortedCodes);
      } catch (error) {
        console.error("Failed to fetch account codes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAccountCodes();
  }, [initialPayableData]);

  const handleInputChange = (event) => {
    setPayable({ ...payable, [event.target.name]: event.target.value });
  };

  const handleSubmit = (formData) => {
    // Run the onSubmit function but don't navigate away
    if (onSubmit) {
      onSubmit(formData);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          label="Invoice Number"
          name="invoiceNo"
          fullWidth
          value={payable.invoiceNo}
          onChange={handleInputChange}
          margin="normal"
        />
        <TextField
          label="Invoice Amount"
          name="invoiceAmount"
          fullWidth
          value={payable.invoiceAmount}
          onChange={(e) => setPayable({ ...payable, invoiceAmount: e.target.value })}
          onBlur={() => {
            // Format the value to two decimals on blur
            setPayable((prev) => ({
              ...prev,
              invoiceAmount: (parseFloat(prev.invoiceAmount) || 0).toFixed(2),
            }));
          }}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            )
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, flexWrap: 'wrap', gap: { xs: .4, sm: 2 } }}>
          <Box sx={{ flex: '1 1 30%' }}>
            <TextField
              label="Paid Amount"
              name="paidAmount"
              fullWidth
              value={payable.paidAmount}
              margin="normal"
              disabled
            />
          </Box>
          <Box sx={{ flex: '1 1 30%' }}>
            <TextField
              label="Check Number"
              name="checkNo"
              fullWidth
              value={payable.checkNo}
              margin="normal"
              disabled
            />
          </Box>
          <Box sx={{ flex: '1 1 30%' }}>
            <TextField
              label="Vendor Name"
              name="vendorName"
              fullWidth
              value={payable.vendor.vendorName}
              onChange={handleInputChange}
              margin="normal"
              disabled
            />
          </Box>
        </Box>
        <TextField
          label="Due Date"
          name="dueDate"
          type="date"
          fullWidth
          value={payable.dueDate}
          onChange={handleInputChange}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <Autocomplete
            options={accountCodes}
            getOptionLabel={(option) => 
              typeof option === 'string' 
                ? option 
                : `${option.accountCode} - ${option.accountDescription}`
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Typography sx={{ fontWeight: 'bold', minWidth: '80px' }}>
                    {option.accountCode}
                  </Typography>
                  <Typography sx={{ ml: 1, flexGrow: 1 }}>
                    - {option.accountDescription}
                  </Typography>
                </Box>
              </li>
            )}
            value={accountCodes.find(code => code._id === payable.expenseCode?._id) || null}
            onChange={(event, newValue) => {
              if (newValue) {
                setPayable({
                  ...payable,
                  expenseCode: {
                    _id: newValue._id,
                    acctCodeId: newValue.accountCode,
                    accountCode: newValue.accountCode,
                    accountDescription: newValue.accountDescription
                  }
                });
              } else {
                setPayable({
                  ...payable,
                  expenseCode: { _id: '', acctCodeId: '', accountCode: '' }
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Expense Code"
                name="expenseCode"
                fullWidth
                margin="normal"
                placeholder="Search by code or description"
                helperText="Search by code number or description"
              />
            )}
            isOptionEqualToValue={(option, value) => 
              option._id === (value?._id || '')
            }
            filterOptions={(options, { inputValue }) => {
              const inputLower = inputValue.toLowerCase();
              return options.filter(option => 
                option.accountCode.toLowerCase().includes(inputLower) || 
                option.accountDescription.toLowerCase().includes(inputLower)
              );
            }}
            ListboxProps={{
              style: { 
                maxHeight: '300px', 
                overflow: 'auto',
                padding: '4px 0'
              }
            }}
            disableListWrap={false}
            limitTags={-1}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            noOptionsText="No matching account codes"
            sx={{
              "& .MuiAutocomplete-listbox": {
                maxHeight: '300px',
                overflowY: 'auto',
              }
            }}
          />
        )}
        <TextField
          label="Internal Comments"
          name="internalComments"
          fullWidth
          value={payable.internalComments}
          onChange={handleInputChange}
          margin="normal"
          multiline
          minRows={2}
          helperText="Prints on one of the A/P Reports (Open)"
        />
        <TextField
          label="External Comments"
          name="externalComments"
          fullWidth
          value={payable.externalComments}
          onChange={handleInputChange}
          margin="normal"
          multiline
          minRows={2}
          helperText="Prints on the check to the vendor"
        />
      </Box>
      <Box sx={{ mt: 4, display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(payable)}
        >
          {initialPayableData ? "Update" : "Add"}
        </Button>
      </Box>
    </Box>
  );
};

export default PayableForm;
