import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PayablesStore from '../../stores/PayablesStore';
import {
  Box, Typography, CircularProgress, Paper, Divider, Grid, IconButton, Tooltip, Snackbar, Alert, Container, Stack, Avatar, Card, CardContent, Modal, Chip
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { format } from 'date-fns';
import { PDFViewer } from '@react-pdf/renderer';
import CheckPDF from '../../pdf/CheckPDF';
import { formatCurrency } from '../../utils/numberFormatting';

const PayableDetails = () => {
  const [payable, setPayable] = useState(null);
  const [otherPayables, setOtherPayables] = useState([]);
  const [loading, setLoading] = useState(false);
  const { payableId } = useParams();
  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [viewPdf, setViewPdf] = useState(false);
  const [checkData, setCheckData] = useState({});

  useEffect(() => {
    setLoading(true);
    PayablesStore.fetchPayableById(payableId)
      .then((fetchedPayable) => {
        setPayable(fetchedPayable);
        setLoading(false);
  
        // Check if this might be part of a multi-invoice payment
        if (fetchedPayable.checkNo && fetchedPayable.checkNo.trim() !== '') {
          const searchFilter = {
            checkNo: fetchedPayable.checkNo,
            _id: { $ne: payableId } // Exclude current payable
          };
          
          PayablesStore.fetchPaginatedPayables(1, 20, '', searchFilter, '', '')
            .then((response) => {
              setOtherPayables(response.results);
            });
        }
      })
      .catch(() => setLoading(false));
  }, [payableId]);

  const handleBack = () => navigate(-1);

  const handleEdit = () => navigate(`/payable/edit/${payableId}`);

  const handleDelete = () => {
    PayablesStore.deletePayable(payable.id)
      .then(() => navigate('/payables'))
      .catch(() => setSnackbar({ open: true, message: 'Error deleting payable', severity: 'error' }));
  };

  const handlePrintPayable = () => {
    const data = {
      checkNumber: payable.checkNo,
      date: new Date().toLocaleDateString(),
      payee: payable.vendor.vendorName,
      amount: payable.invoiceAmount,
      text: `Invoice No: ${payable.invoiceNo}, Amount: $${payable.invoiceAmount.toFixed(2)}`
    };
    setCheckData(data);
    setViewPdf(true);
  };

  const handleCardClick = (id) => {
    navigate(`/payable/${id}`);
  }

  const formatDate = (date) =>
    date ? format(new Date(date), 'MM/dd/yyyy') : 'N/A';

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton component={Link} to="/payables">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h4" sx={{ ml: 2 }}>Payable Details</Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Tooltip title="Edit Payable">
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Print Payable">
              <IconButton onClick={handlePrintPayable}>
                <PrintIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Payable">
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                  <Avatar sx={{ width: 56, height: 56, bgcolor: 'primary.main' }}>{payable?.vendor?.vendorName?.[0]}</Avatar>
                  <Box>
                    <Typography variant="h6">{payable?.vendor?.vendorName || 'Vendor Name Not Available'}</Typography>
                    {payable?.vendor?.email && <Typography variant="body2" color="textSecondary">{payable.vendor.email}</Typography>}
                    {payable?.vendor?.phone && <Typography variant="body2" color="textSecondary">{payable.vendor.phone}</Typography>}
                  </Box>
                </Stack>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary">Vendor ID: {payable?.vendor?.vendorId || 'N/A'}</Typography>
                <Typography variant="body2" color="textSecondary">Address: {payable?.vendor?.address || 'N/A'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <DescriptionIcon sx={{ mr: 1 }} /> Invoice Information
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Number: {payable?.invoiceNo || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Amount: {payable?.invoiceAmount ? formatCurrency(payable.invoiceAmount) : 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Invoice Date: {formatDate(payable?.invoiceDate)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Due Date: {formatDate(payable?.dueDate)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Date Received: {formatDate(payable?.dateRecd)}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2"><strong>Status:</strong></Typography>
                  <Chip
                    label={
                      payable?.paidAmount >= payable?.invoiceAmount
                        ? 'Paid'
                        : payable?.paidAmount > 0
                          ? 'Partially Paid'
                          : 'Unpaid'
                    }
                    color={
                      payable?.paidAmount >= payable?.invoiceAmount
                        ? 'success'
                        : payable?.paidAmount > 0
                          ? 'warning'
                          : 'error'
                    }
                    size="small"
                  />
                </Box>
                <Typography variant="body2" color="textSecondary"><strong>To Be Paid:</strong> {payable?.payThis ? 'Yes' : 'No'}</Typography>
                <Typography variant="body2" color="textSecondary"><strong>Void:</strong> {payable?.void ? 'Yes' : 'No'}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <AttachMoneyIcon sx={{ mr: 1 }} /> Payment Details
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Paid Amount:</strong> {payable?.paidAmount !== undefined ? formatCurrency(payable.paidAmount) : 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Outstanding Amount:</strong> {payable?.invoiceAmount && payable?.paidAmount !== undefined ? 
                    formatCurrency(Math.max(0, payable.invoiceAmount - payable.paidAmount)) : 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Check Number:</strong> {payable?.checkNo || 'Not paid'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Date Paid:</strong> {formatDate(payable?.datePaid)}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="textSecondary">
                  <strong>Payment Method:</strong> {payable?.checkNo ? 'Check' : 'Not paid'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Payment Notes:</strong> {payable?.paymentNotes || 'None'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountBalanceIcon sx={{ mr: 1 }} /> Account Information
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Expense Code:</strong> {payable?.expenseCode?.accountCode || 'N/A'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Expense Description:</strong> {payable?.expenseCode?.accountDescription || 'N/A'}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <Typography variant="subtitle1" gutterBottom>Comments</Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>Internal Comments:</strong> {payable?.commentsInternal || 'None'}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  <strong>External Comments:</strong> {payable?.commentsExternal || 'None'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>Audit Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Created By:</strong> {payable?.enterBy || 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Created Date:</strong> {formatDate(payable?.enterDate)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Last Modified By:</strong> {payable?.modifiedBy || 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Last Modified Date:</strong> {formatDate(payable?.modifiedDate)}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {otherPayables.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Other Payables on this Check Number</Typography>
          <Grid container spacing={2}>
            {otherPayables.map((otherPayable) => (
              <Grid item xs={12} key={otherPayable.id}>
                <Card onClick={() => handleCardClick(otherPayable._id)} style={{ cursor: 'pointer' }}>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {otherPayable.vendor?.vendorName || "Vendor not available"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Invoice No: {otherPayable.invoiceNo || "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Due Date: {otherPayable.dueDate ? formatDate(otherPayable.dueDate) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Total Amount: {otherPayable.invoiceAmount ? formatCurrency(otherPayable.invoiceAmount) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Unpaid Amount: {otherPayable.invoiceAmount ? formatCurrency(Math.max(0, otherPayable.invoiceAmount - (otherPayable.paidAmount || 0))) : "N/A"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Paid Amount: {otherPayable.paidAmount !== undefined ? `$${otherPayable.paidAmount.toFixed(2)}` : 'N/A'}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      
      {viewPdf && (
        <Modal
          open={viewPdf}
          onClose={() => setViewPdf(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}
        >
          <Box sx={{ width: '90%', height: '90%', padding: 1 }}>
            <IconButton onClick={() => setViewPdf(false)} sx={{ position: 'absolute', right: 16, top: 16, color: 'white' }}>
              <CloseIcon />
            </IconButton>
            <PDFViewer width="100%" height="100%">
              <CheckPDF report={checkData} />
            </PDFViewer>
          </Box>
        </Modal>
      )}

    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    </Container>
  );
};

export default PayableDetails;
