import {
    Box,
    TextField,
    Grid,
    Button,
    Typography,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddressEdit from "../../components/AddressEdit";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const EmployeeForm = ({ employee, onSubmit, onDelete, onChange, back }) => {

    const handleChange = (event) => {
        const {name, value} = event.target;
        onChange({
            ...employee,
            [name]: value
        })
    }

    const handleSubChange = (type, event) => {
        onChange({
            ...employee,
            [type]: { ...employee[type], [event.target.name]: event.target.value },
        });
    }

    const handleDateChange = (name, date) => {
        const vaidDate = date instanceof Date && !isNaN(date) ? date : null;
        onChange({
            ...employee,
            [name]: vaidDate
        })
    };
            
    const handleAddressChange = (name, address) => {
        onChange({
            ...employee,
            [name]: {...employee[name], ...address}
        })
    };

    const initialDates = {
        birthDate: employee.birthDate ? new Date(employee.birthDate) : null,
        hireDate: employee.hireDate ? new Date(employee.hireDate) : null,
        finalDate: employee.finalDate ? new Date(employee.finalDate) : null,
        honeDate: employee.honeDate ? new Date(employee.honeDate) : null,
        serviceHire: employee.serviceHire ? new Date(employee.serviceHire) : null,
        serviceHone: employee.serviceHone ? new Date(employee.serviceHone) : null,
    }

    const handleCheckboxChange = (event) => {
        const { name, value, type, checked } = event.target;
        onChange({
            ...employee,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <Box sx={{ p: 3 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" mt={4} mb={2} gutterBottom>Basic Information</Typography>
                    <TextField fullWidth name="firstName" label="First Name" value={employee.firstName} onChange={handleChange} margin="normal"/>
                    <TextField fullWidth name="middleName" label="Middle Name" value={employee.middleName} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="lastName" label="Last Name" value={employee.lastName} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="email" label="Email" value={employee.email} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="phoneNumber" label="Phone Number" value={employee.phoneNumber} onChange={handleChange} margin="normal" />
                    <DatePicker sx= {{ mt: 2, mb: 2, width: '100%' }} name="birthDate" label="Birth Date" value={initialDates.birthDate} onChange={(date) => handleDateChange('birthDate', date)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <AddressEdit
                        address={employee.address}
                        fieldNames={{name: "name", street1: "street", street2: "line2", city: "city", state: "state", zip: "postalCode", country: "country"}}
                        onChange={(event) => handleAddressChange("address", event)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" mt={4} mb={2} gutterBottom>Employment Information</Typography>
                    <TextField fullWidth name="employeeId" label="Employee ID" value={employee.employeeId} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="nameID" label="Name ID" value={employee.nameID} onChange={handleChange} margin="normal" />
                    <FormControlLabel control={<Checkbox name="fringeBenefits3Mo" checked={Boolean(employee.fringeBenefits3Mo)}  onChange={handleCheckboxChange}/> } label="Fringe Benefits 3 Months"/>
                    <FormControlLabel control={<Checkbox name="fringeBenefits6Mo" checked={Boolean(employee.fringeBenefits6Mo)}  onChange={handleCheckboxChange}/> } label="Fringe Benefits 4 Months"/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" mt={4} mb={2} gutterBottom>Additional Employment Information</Typography>
                    <DatePicker sx={{ mt: 2, mb: 1, width: '100%'}} name="hireDate" label="Hire Date" value={initialDates.hireDate} onChange={(date) => handleDateChange('hireDate', date)} />
                    <DatePicker sx={{ mt: 2, mb: 1, width: '100%' }} name="finalDate" label="Final Date" value={initialDates.finalDate} onChange={(date) => handleDateChange('finalDate', date)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" mt={4} mb={2} gutterBottom>Pay Information</Typography>
                    <TextField fullWidth name="currentHourlyRate" label="Current Hourly Rate" value={employee.currentHourlyRate} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="currentOTRate" label="Current OT Rate" value={employee.currentOTRate} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="currentSalary" label="Current Salary" value={employee.currentSalary} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="hireHourlyRate" label="Hire Hourly Rate" value={employee.hireHourlyRate} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="hireOTRate" label="Hire OT Rate" value={employee.hireOTRate} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="hireSalary" label="Hire Salary" value={employee.hireSalary} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="titleCode" label="Title Code" value={employee.titleCode} onChange={handleChange} margin="normal" />
                    <TextField fullWidth name="employmentType" label="Employment Type" value={employee.employmentType} onChange={handleChange} margin="normal" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" mt={4} mb={2} gutterBottom>Personal Information</Typography>
                    <DatePicker sx={{ mt:2 , mb: 1, width: '100%'}} name="honeDate" label="Hone Date" value={initialDates.honeDate} onChange={(date) => handleDateChange('honeDate', date)} />
                    <DatePicker sx={{ mt: 2, mb: 1, width: '100%'}} name="serviceHire" label="Service Hire" value={initialDates.serviceHire} onChange={(date) => handleDateChange('serviceHire', date)} margin="normal" />
                    <DatePicker sx={{ mt: 2, mb: 1, width: '100%'}} name="serviceHone" label="Service Hone" value={initialDates.serviceHone} onChange={(date) => handleDateChange('serviceHone', date)} margin="normal" />
                    <TextField fullWidth name="socialSecurityNumber" label="Social Security Number" value={employee.socialSecurityNumber} onChange={handleChange} margin="normal" />
                </Grid>
            </Grid>
            </LocalizationProvider>
            <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
                <Button
                    variant="outlined"
                    onClick={() => back()}
                >
                    Back to Employees
                </Button>

                <Box sx={{ display: "flex", gap: 2 }}>
                    {onDelete && (
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => onDelete(employee._id)}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Box>
        </Box>
    )

};

export default EmployeeForm;